import { get, post, put } from '@/utils/request'

export default {
  getCostType(params = {}) {
    return get(`/rest/order/cost`, params)
  },
  getDipmList(params = {}) {
    return get(`/rest/customer/getDipmList`, params)
  },
  add(data) {
    return post(`/rest/order/customer`, data)
  },
  update(uuid, data) {
    return put(`/rest/order/${uuid}`, data)
  },
  getList(params = {}) {
    return get(`/rest/customer/orders`, params)
  },
  detail(uuid) {
    return get(`/rest/order/${uuid}`)
    /*
    return get(`/rest/order?order_id=${uuid}`)
    .then(res => {
      return res && res.list && res.list[0]
    })
    */
  },
  pay(uuid, type, method) {
    return get(`/rest/pay/yaband/${uuid}`, { pay_type: type, pay_method: method })
  },
  // VISA / MASTER CARD
  payFinland(uuid, type) {
    return get(`/rest/payment/pay/${uuid}`, { pay_type: type })
  },
  pickup(itemId, params) {
    return get(`/rest/post/nord/pickup/${itemId}`, params)
  },
  pickupSelf(itemId) {
    return put(`/rest/post/nord/pickup/self-delivery`, { itemId })
  },
  swishPay(uuid, params) {
    return get(`/rest/pay/swish/${uuid}`, params)
  },
  klarnaPay(uuid, type) {
    return get(`/rest/pay/klarna/${uuid}?pay_type=${type}`)
  },
  klarnaRetrieve(order_id, klarna_order_id) {
    return get(`/rest/pay/klarna/retrieve/${order_id}`, { klarna_order_id })
  },
}
